/* eslint-disable camelcase */
import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import {
  PageLayout,
  PageHeading,
  SuccessStories,
  FAQ,
  HomeServices,
  // HomeQuiz,
  QuizModal,
  HomePopUp,
  Fitin56Banner,
  Brands,
  // ImageVideoSection,
  // HomePageReviews,
  CTASection,
  ReviewsAndRatings,
  ContentSideImageSection,
} from 'components';
// import { SlideIn } from 'components/animations';
import { BrandQuery } from 'queries/brand';
import { HomePagePropsTypes } from 'types';
import {
  showHomePageQuizPrevQuestion,
  showHomePageQuizNextQuestion,
  homePageQuizModalOpen,
  homePageQuizModalClose,
  submitHomePageQuizAndPreviewAnswers,
  updateHomePageQuizPreviewStatus,
  updateHomePageQuizSummaryReviewedStatus,
  resetHomePageQuiz,
} from 'Redux/actions';

// Todo get seo data for all pages
const IndexPage: FC<HomePagePropsTypes> = ({ data, pageContext }: any) => {
  const {
    HomePageQuizReducer: {
      currentQuestion,
      submitedQuestions,
      showQuizSummary,
      isQuizSummaryReviewed,
    },
    QuizModalReducer: { isHomePageQuizModalOpen },
  } = useSelector((state: any) => {
    return state;
  });
  const {
    HomePage: {
      title,
      // tagline,
      pageTheme,
      // videoImage,
      faqSection: { title: faqTitle, tagline: faqTagline, faqs },
      ReviewAndRating,
      fitin56Banner,
      homePageQuiz,
      seo,
      banner,
      homePageServiceSection: {
        homePageServices,
        tagline: serviceSectionTagline,
        title: serviceSectionTitle,
      },
      homePageSucessStories,
      CTABanner,
      ContentSideImage,
    },
  } = data;
  const brandquery = BrandQuery();
  const { brandsWorked } = brandquery.Brands;
  const HomePageSucessStories = data.successStories.edges.filter(story => {
    let ishomePageSuccesStory = false;
    const homepagesuccessStoriesId =
      homePageSucessStories.homePageSuccessStory.map(element => {
        return element.success_story.id;
      });
    ishomePageSuccesStory =
      ishomePageSuccesStory ||
      homepagesuccessStoriesId.includes(story.node.strapiId);
    return ishomePageSuccesStory;
  });
  const FilteredHomePageSuccessStory = HomePageSucessStories.map(
    (item: any) => {
      return item.node;
    }
  );

  const dispatch = useDispatch();
  const handleModalOpen = () => {
    if (!isHomePageQuizModalOpen) {
      dispatch(homePageQuizModalOpen());
      window.history.pushState('', '', `/quiz`);
    }
  };
  const handleModalclose = () => {
    dispatch(homePageQuizModalClose());
    window.history.pushState('', '', `/`);
  };
  useEffect(() => {
    if (
      window.location.pathname === pageContext.slug ||
      window.location.pathname === `${pageContext.slug}/`
    ) {
      handleModalOpen();
    }
  }, [pageContext.slug]);
  return (
    <>
      <PageLayout
        title="Home"
        className={`${pageTheme}`}
        delaySec={0.8}
        showBrand={false}
        showReviews
        metaTitle={seo?.metaTitle ?? title}
        description={seo?.metaDescription}
        image={seo?.shareImage?.localFile?.publicURL}
        type="Home"
        centerAlign
        bannerTitle={banner?.title}
        bannerTagline={banner?.tagline}
        bannerImage={banner?.image}
        bannerButton={banner?.ctaButton}
        bannerPlaceholder={banner?.postcodePlaceholder}
        showBgImage
        pageClassName="home"
        faqs={faqs}
      >
        <>
          <ReviewsAndRatings data={ReviewAndRating} />
          {/* <HomePageReviews /> */}
          <ContentSideImageSection data={ContentSideImage} />
          {/* <ImageVideoSection data={videoImage} /> */}

          <div className="container">
            <SuccessStories
              title={homePageSucessStories.title}
              tagline={homePageSucessStories.tagline}
              sucessStorySlides={FilteredHomePageSuccessStory}
            />
          </div>
          <div className="container">
            <HomeServices
              title={serviceSectionTitle}
              tagline={serviceSectionTagline}
              homePageServices={homePageServices}
            />
          </div>
          {fitin56Banner && <Fitin56Banner info={fitin56Banner} />}
          <CTASection
            data={CTABanner}
            modalTitle={
              brandquery.strapiContactUsPage.locationsSection
                .contactFormModalTitle
            }
            dealTitle={title}
          />
          <Brands brandsWorked={brandsWorked} />

          <div className="container">
            {/* <div className="HomePage-title-icon-wrapper">
              <div>
                <PageHeading title={title} subtitle={tagline} level={1} />
              </div>
            </div>
            <SlideIn>
              <HomeQuiz
                QuizWizardData={homePageQuiz}
                handelQuizModalOpen={handleModalOpen}
              />
            </SlideIn> */}
            <section className="home-page-faq-section">
              <PageHeading title={faqTitle} subtitle={faqTagline} />
              <FAQ Faqs={faqs} />
            </section>
          </div>
        </>
      </PageLayout>
      <QuizModal
        isQuizModalOpen={isHomePageQuizModalOpen}
        portalClassName="home-page-quiz-modal"
        handelQuizModal={handleModalclose}
        QuizQuestions={data.HomePageQuizQuestions}
        currentQuestion={currentQuestion}
        submitedQuestions={submitedQuestions}
        showPrevQuestion={showHomePageQuizPrevQuestion}
        showNextQuestion={showHomePageQuizNextQuestion}
        previewAnswer={submitHomePageQuizAndPreviewAnswers}
        showQuizSummary={showQuizSummary}
        toogleQuizPreview={updateHomePageQuizPreviewStatus}
        isQuizSummaryReviewed={isQuizSummaryReviewed}
        updateQuizSummaryReviewedStatus={
          updateHomePageQuizSummaryReviewedStatus
        }
        resetQuiz={resetHomePageQuiz}
        formSuccessMessage={homePageQuiz.formSuccessMessage}
        formSuccessTitle={homePageQuiz.formSuccessTitle}
        dealTitle="Fitness Quiz"
      />
      <HomePopUp />
    </>
  );
};
export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    HomePage: strapiHomePage {
      title
      tagline
      pageDescription
      pageTheme
      enquireButtonText
      contactFormModalTitle
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      ReviewAndRating {
        title
        reviewPlatform {
          id
          link
          title
          rating
          review
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      faqSection {
        faqs {
          title
          answer
          id
        }
        title
        tagline
      }
      ContentSideImage {
        hasBg
        description
        id
        title
        tagline
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      homePageQuiz {
        title
        tagline
        description
        quizAction {
          title
          description
        }
        quizStep {
          title
          icon
        }
        quizCTA {
          title
          description
          buttonText
        }
        formSuccessMessage
        formSuccessTitle
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      fitin56Banner {
        ctaButton
        ctaLink
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
      CTABanner {
        ctaButton
        postcodePlaceholder
        title
        tagline
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      videoImage {
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
      videoImage {
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
      homePageDietition {
        ctaButton
        ctaLink
        description
        title
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
      homePageServiceSection {
        id
        tagline
        title
        homePageServices {
          description
          icon
          id
          tagline
          title
          slug
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 400, height: 400)
              }
              publicURL
            }
          }
        }
      }
      homePageArticles {
        title
        tagline
      }

      homePageSucessStories {
        title
        tagline
        homePageSuccessStory {
          success_story {
            id
          }
        }
      }
    }
    homeArticles: allStrapiArticle(sort: { fields: strapiId, order: DESC }) {
      edges {
        node {
          id
          strapiId
          title
          description
          slug
          content
          video {
            localFile {
              publicURL
            }
          }
          imageLink
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    strapiServicesPage {
      slug
    }
    Learninghub: strapiLearningHubPage {
      slug
      title
    }
    successStories: allStrapiSuccessStory {
      edges {
        node {
          id
          strapiId
          name
          content
          highlight
          videoImage {
            image {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
                publicURL
              }
            }
            video {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    HomePageQuizQuestions: allStrapiQuestions(
      filter: { questionCategory: { eq: "homePageQuizQuestion" } }
    ) {
      edges {
        node {
          id
          questionOrderNumber
          question
          questionType
          description
          likertScaleQuestionOptions {
            id
            optiontitle
            description
            scaleMaxLabel
            scaleMinLabel
            likertScaleOptions {
              summary
              optionSummaryContent
              id
            }
          }
          multipleChoiceQuestionTypeOptions {
            id
            value
            optionSummaryContent
          }
          TextQuestionTypeOption {
            id
            value
            optionSummaryContent
          }
        }
      }
    }
    strapiBlogPage {
      slug
    }
  }
`;
